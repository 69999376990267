import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import dashboard from "../../styles/dashboard.module.scss";
import PointHistory from '../../components/PointHistory/PointHistory';
import TopHook from "../../Hooks/TopHook"; 

export default function Pointhistory() {


 const {
    toggle,
    setToggle,
    loader,
    pageCount,
    globalSearch,
    setLoader,
    handleFetch,
    currentPage,
    handlePageChange,
    handleDownload,
    items
  } = TopHook();

  return (
    <>
      <div className={dashboard.wrapper_main}>
        <Sidebar sidebar={toggle} DashboardPage="DashboardPage" />

        <div className={dashboard.page_content}>
          <Header heading="Point History" changeSidebar={setToggle} />

          <div className={dashboard.inner_content}>
            <Container fluid>
              <Row> 
                <Col lg={12} md={12} xs={12}>
                  <PointHistory
                    items={items}
                    loader={loader}
                    pageCount={pageCount}
                    setLoader={setLoader}
                    handleFetch={handleFetch}
                    globalSearch={globalSearch}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleDownload={handleDownload}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify"; 
import OrganizationConstants from "../Constants/OrganizationConstants/OrganizationConstants";
import { toastProps } from "../Services/_apiResponses/apiResponse.service";
import { UniversalCreateUpdateService } from "../Services/_private/UniversalCreateUpdateService/universalCreateUpdate.service";
import UseExportImport from "./UseExportImport";
import UseGetApi from "./UseGetApi";
import UseToggle from "./UseToggle";

const UseUsers = (GETORGANIZATIONS_API_ENDPOINT, UPDATEUSERS_API_ENDPOINT) => {
  const { universalCreateUpdate } = UniversalCreateUpdateService;
  const {
    handleDownload,
    // , onFileChange
  } = UseExportImport();
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const navigate = useNavigate();

  const [uuid, setUuid] = useState("");
  const [declineReason, setDeclineReason] = useState("");

  const {
    items, 
    pageCount,
    currentPage,
    handlePageChange,
    search,
    globalSearch,
    loader,
    setLoader, 
    handleFetch,
    onFileChange,
  } = UseGetApi(GETORGANIZATIONS_API_ENDPOINT);


  const {
    DELETE_API_ENDPOINT
  } = OrganizationConstants(uuid);

 
  // MODAL DECLINE................

  const handleShowDecline = (e) => {
    let uuid = e.target.getAttribute("data-uuid");
    setUuid(uuid);
    console.log(e);
    setToggle1();
  };

  const rejectUsers = async () => {
    if (declineReason === "") {
      toast.error(`${"Please Provide the Reason for Decline"}`, toastProps);
    } else {
      let userStatus = {};
      userStatus.uuid = uuid;
      userStatus.status = "rejected";
      userStatus.comment = declineReason;
      setToggle1();
      setLoader(true);
      setDeclineReason("");
      let response = await universalCreateUpdate( 
        userStatus,
        "POST",
        UPDATEUSERS_API_ENDPOINT
      );
      console.log(response);
      handleFetch(currentPage, GETORGANIZATIONS_API_ENDPOINT, "");
    }
  };

  // MODAL APPROVE...................

  const handleShowApprove = (e) => {
    let uuid = e.target.getAttribute("data-uuid");
    setUuid(uuid);
    console.log(e);
    setToggle();
  };

  const approvedUser = async () => {
    let userStatus = {};
    userStatus.uuid = uuid;
    userStatus.status = "approved";
    setToggle();
    setLoader(true);
    let response = await universalCreateUpdate(
      userStatus,
      "POST",
      UPDATEUSERS_API_ENDPOINT
    );
    handleFetch(currentPage, GETORGANIZATIONS_API_ENDPOINT, "");
    console.log(response);
  };
 
 
 const deleteOrg = async (e) => {
    let uuid = e.target.getAttribute("data-uuid");
    setLoader(true);
     let userStatus = {}; 
    userStatus.uuid = uuid;

    let response = await universalCreateUpdate(
      userStatus,
      "DELETE",
      DELETE_API_ENDPOINT+'/'+uuid
    );   
     handleFetch(currentPage, GETORGANIZATIONS_API_ENDPOINT, "");
  };

  // REASON FOR DECLINE
  const reasonForDecline = (e) => {
    setDeclineReason(e.target.value);
  };

  const handleOnSelect = (e) => {
    let category = e.target.value;
    setLoader(true);
    handleFetch(currentPage, GETORGANIZATIONS_API_ENDPOINT, category, search);
  };

  return {
    toggle1,
    setToggle1,
    rejectUsers,
    reasonForDecline,
    toggle,
    setToggle,
    handleFetch,
    approvedUser,
    uuid,
    globalSearch,
    handleOnSelect,
    handleDownload,
    onFileChange,
    loader, 
    deleteOrg,
    items,
    handleShowDecline,
    navigate,
    handleShowApprove,
    pageCount,
    handlePageChange,
  };
};

export default UseUsers;

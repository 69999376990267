import React, { useState } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import { DatePicker } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons"; // Import the close icon
import moment from "moment"; 
import userTabbing from "../../styles/userTabbing.module.scss";
import { MenuDropdown } from "../../components/TableImportButton/button";
import Pagination from "../../components/Pagination/Pagination";
import Searchbar from "../../components/Searchbar/Searchbar";
import Loading from "../../components/Loading/Loading";
import { DownloadButtonRank } from "../../components/TableImportButton/button";
import TableHead from "../../components/TableHead/TableHead";
import TopuserConstants from "../../Constants/UsersConstants/TopuserConstants";

const { RangePicker } = DatePicker;

const TopUser = ({
  items,
  loader,
  pageCount,
  handlePageChange,
  handleShowApprove,
  deleteUser,
  handleDownload,
  navigate,
  setLoader,
  handleFetch,
  currentPage,
  globalSearch,
}) => {
  // Define date format
  const dateFormat = "MM-DD-YYYY";

  // Get API endpoints
  const {
    GETUSERS_API_ENDPOINT,
    UPDATEUSERS_API_ENDPOINT,
    DELETE_API_ENDPOINT,
    EXPORT_API_ENDPOINT,
    IMPORT_API_ENDPOINT
  } = TopuserConstants();

  // Track the selected date range in the state
  const [dateRange, setDateRange] = useState(null);

  // Handle date range picker
  const dateMonthPicker = (range) => {
    if (!range || range.length === 0) {
      console.error("No date range selected");
      return;
    }

    const startDateValue = range[0].startOf('day').format('YYYY-MM-DD');
    const endDateValue = range[1].endOf('day').format('YYYY-MM-DD');

    setDateRange(range); // Set the selected date range in the state
    setLoader(true); // Set loader to true before fetch

    handleFetch(currentPage, GETUSERS_API_ENDPOINT, { startDate: startDateValue, endDate: endDateValue, filterCategory: "" })
      .finally(() => setLoader(false)); // Set loader to false after fetch
  };

  // Reset the date picker
  const resetDateRange = () => {
    setDateRange(null); // Clear the selected date range
    handleFetch(currentPage, GETUSERS_API_ENDPOINT, { startDate: '', endDate: '', filterCategory: "" });
  };

  return (
    <>
      <div className={userTabbing.table_user}>
        <div> 
          <Row>
            <Col lg={3} sm={12}>
              <Searchbar SPH="Search Dealer Code" OnChange={(e) => globalSearch(e.target.value)} />
            </Col>

           {/*  <Col lg={3} sm={12}>
              <RangePicker
                value={dateRange} // Set the value to the selected date range
                onChange={dateMonthPicker}
                allowClear={false}
                disabledDate={(current) => current && current > moment().endOf('day')}
              />
              Conditionally show the close icon only when a date range is selected 
              {dateRange && (
                <CloseCircleOutlined
                  onClick={resetDateRange}
                  style={{ fontSize: '20px', color: '#7F00FF', marginLeft: '10px', cursor: 'pointer' }}
                />
              )}
            </Col>*/}

            <Col lg={9} sm={12} className="text-right">
              <DownloadButtonRank
                ExportFile={() => handleDownload(EXPORT_API_ENDPOINT)}
              />
            </Col>
          </Row>
        </div>

        {loader ? (
          <Loading />
        ) : (
          <>
            <div className="table-responsive table-responsivex">
              <Table responsive="sm">
                <TableHead
                  CLabel="Full Name"
                  One="Points"
                  Two="Date"
                  Three="Sales" 
                />

                <tbody>
                  {items && items.length > 0 ? (
                    items.map(({ name, points, date, sales, uuid }, index) => (
                      <tr key={uuid || index}>
                        <td>{name}</td>
                        <td className="text-center">{points}</td>
                        <td className="text-center">{date ? moment(date).format(dateFormat) : '-'}</td>
                        <td className="text-center">{sales}</td>
                        {/* <td className="text-center">
                          <div className={userTabbing.buttond}>
                            <MenuDropdown
                              Option1="Approve"
                              Option2="User Details! "
                              Option3="Delete"
                              Uuid={uuid}
                              Action1={handleShowApprove}
                              Action2={() => navigate(`/admin/user/${uuid}`)}
                              Action3={deleteUser}
                            />
                          </div>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">No Data Available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}

        <Pagination PageCount={pageCount} ChangePage={handlePageChange} />
      </div>
    </>
  );
};

export default TopUser;

import config from "../../../config/config.json";
import { ApiResponse } from "../../_apiResponses/apiResponse.service";

const { headers, headersWithAuthWithoutBody } = ApiResponse;

// GET SERVICE..........................

const universalGetList = async (query, endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}?search=${query.search}&skip=${query.skip}&limit=${query.limit}&filter=${query.filter}&awardId=${query.awardId}&type=${query.type}&RankCategory=${query.RankCategory}&startDate=${query.startDate}&endDate=${query.endDate}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};

const universalGetListNew = async (query, endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}?search=${query.search}&skip=${query.skip}&limit=${query.limit}&filter=${query.filter}&awardId=${query.awardId}&type=${query.type}&RankCategory=${query.RankCategory}&startDate=${query.startDate}&endDate=${query.endDate}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};

// GET WITHOUT SEARCH AND PAGINATION..........................

const getProfileData = async (endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};

const getPrivateBadges = async (endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};

const getUserPoint = async (endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};

const getNotificaion = async (endpoint) => {
  const response = await fetch(
    `${config.API_URL}${endpoint}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
};


export const UniversalGetService = {
  universalGetList,
  universalGetListNew,
  getProfileData,
  getPrivateBadges,
  getUserPoint,
  getNotificaion,
};

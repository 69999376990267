import React, { useState, useEffect, useTransition } from "react";
import { Table, Col, Row } from "react-bootstrap";
import tabbingModule from "../../styles/tabbing.module.scss";
import Pagination from "../../components/Pagination/Pagination";
import Searchbar from "../../components/Searchbar/Searchbar";
import Loading from "../../components/Loading/Loading";
import PointHistories from "../../Constants/PointHistories";
import UseGetApi from "../../Hooks/UseGetApi";
import { UniversalGetService } from "../../Services/_private/UniversalGetService/universalGet.service";
import { useRef } from "react";
import TableHead from "../../components/TableHead/TableHead";

const PointHistory = () => {
    const [uuid, setUuid] = useState("");
    const { loader, setLoader } = UseGetApi();
    const { getPrivateBadges } = UniversalGetService;
    const fetchOrgRef = useRef();
    const { GET_API_ENDPOINT, EXPORT_API_ENDPOINT } = PointHistories(uuid);
    const { globalSearch, handlePageChange, items, pageCount } = UseGetApi(GET_API_ENDPOINT);
    const [assignBadge, setAssignBadge] = useState();
    const [privateBadgesList, setPrivateBadgesList] = useState([]);

    const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // handle null or undefined dates
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            const fetchData = async () => {
                setLoader(true);
                try {
                    const response = await getPrivateBadges("admin/point/getHistory");
                    setPrivateBadgesList(response.data);
                    console.log("responsex", response.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setLoader(false);
                }
            };

            fetchOrgRef.current = fetchData;
            fetchData();
        });
    }, [getPrivateBadges, setLoader]);

    return (
        <>
            <div className={tabbingModule.table_d}>
                <Row>
                    <Col xxl={4} xl={4} lg={3} md={12} sm={12}>
                        <Searchbar
                            SPH="Search History"
                            OnChange={(e) => globalSearch(e, GET_API_ENDPOINT)}
                            Value1=""
                            Filter1="Select Earn Points"
                        />
                    </Col>
                </Row>

                {loader || isPending ? (
                    <Loading />
                ) : (
                    <div className="table-responsive cust_table">
                        <Table responsive="sm">
                            <TableHead
                                CLabel="Name"
                                One="Points"
                                Two="Description"
                                Three="Date"
                                Four="Assign By"
                            />
                            <tbody>
                                {items?.map((history, index) => (
                                    <tr key={index}>
                                        <td>{history?.user?.name || "N/A"}</td>
                                        <td className="text-center">{history?.points || "N/A"}</td>
                                        <td className="text-center">{history?.pointDescription || "N/A"}</td>
                                        <td className="text-center">{formatDate(history?.createdAt) || "N/A"}</td>
                                        <td className="text-center">
                                            {history?.admin?.firstName || "N/A"} {history?.admin?.lastName || "N/A"} <br />
                                            <small className="muted">{history?.admin?.email || "N/A"}</small>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}

                <Pagination PageCount={pageCount} ChangePage={(e) => handlePageChange(e, GET_API_ENDPOINT)} />
            </div>
        </>
    );
};

export default PointHistory;

const TopuserConstants = () => {
    const GETUSERS_API_ENDPOINT = "admin/teamsdataall";
    const UPDATEUSERS_API_ENDPOINT = "admin/users/request";
    const EXPORT_API_ENDPOINT = "admin/orders/export";
    const IMPORT_API_ENDPOINT = "admin/users/import";
    const DELETE_API_ENDPOINT = "admin/user";
  
    return {
      GETUSERS_API_ENDPOINT,
      UPDATEUSERS_API_ENDPOINT,
      EXPORT_API_ENDPOINT, 
      IMPORT_API_ENDPOINT,
      DELETE_API_ENDPOINT,
    };
  };
  
  export default TopuserConstants;
  
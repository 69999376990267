const PointHistories = (uuid) => {
    const GET_API_ENDPOINT = "admin/point/getHistory";
    // const CREATE_UPDATE_API_ENDPOINT = "admin/redeemHistory";
    // const UPDATE_API_ENDPOINT = `admin/redeemHistory/${uuid}`;
    // const EXPORT_API_ENDPOINT = "admin/redeemHistory/export"; 
  
    // const FormDetails = {
    //   getFile: "",
    //   Username: "", 
    //   prize: "",
    //   points: "", 
    // };
  
    return {
      GET_API_ENDPOINT,
    //   CREATE_UPDATE_API_ENDPOINT,
    //   UPDATE_API_ENDPOINT,
    //   EXPORT_API_ENDPOINT, 
    //   FormDetails, 
    };
  };
  
  export default PointHistories;
  
import { toast } from "react-toastify";
import config from "../../../config/config.json";
import {
  ApiResponse,
  toastProps,
} from "../../_apiResponses/apiResponse.service";

const { headers, headersWithAuth, handleResponse } = ApiResponse;

// CREATE/UPDATE SERVICE....................

const universalCreateUpdate = async (dataParams, method, endpoint) => {
  try {
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      headersWithAuth(method, dataParams, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

// API CALL WITH UPLOAD IMAGES......................

const uploadImages = async (userData, endpoint, method) => {
  try {
    let headersData = headers;
    const headersd = {
      authorization: headersData.authorization,
      Accept: "application/json",
    };

    let requestOptions = {
      method: method || "POST",
      headers: headersd,
      body: userData,
      redirect: "follow",
    };
    const response = await fetch(
      `${config.API_URL}${endpoint}`,
      requestOptions
    );
    const data = await response.json();
    handleResponse(data);
  } catch (error) {
    toast.error(`${error || "Please try again later..!"}`, toastProps);
  }
};

const uploadPoints = async (userData, endpoint, method = "PUT") => {
  try { 
    const headersd = {
      authorization: headers.authorization,
      Accept: "application/json",
      "Content-Type": "application/json", 
    };
 
    let requestOptions = {
      method: method,
      headers: headersd,
      body: JSON.stringify(userData),  
      redirect: "follow",
    };
 
    const response = await fetch(`${config.API_URL}${endpoint}`, requestOptions);
 
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); 
    handleResponse(data);  
  } catch (error) {
    console.error("Error during API call:", error);
    toast.error(`${error.message || "Please try again later..!"}`, toastProps);
  }
};


export const UniversalCreateUpdateService = {
  universalCreateUpdate,
  uploadImages,
  uploadPoints,
};
